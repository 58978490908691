import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams, useOutletContext } from "react-router-dom";
import { api } from "../../../utils/Api";
import Skeleton from "react-loading-skeleton";
import { AppConfig } from "../../../config";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";

export default function BrandManageProductsUI(props: any) {
  const context = useContext(DataContext);
  const OutletContext = useOutletContext();
  const [width, SetWidth] = useState(innerWidth);

  return (
    <div className="">
      <div
        className={OutletContext ? "head-bar sticky-sm py-3" : "head-bar py-3"}
        style={OutletContext == false ? { width: width } : {}}
      >
        <div className="head-text mb-2">Manage Products</div>
        <div className="d-flex align-items-center w-100">
          <div className="w-50 pe-1">
            <div className="p-2 radius-10 bg-body-color">
              <label htmlFor="outlet" className="custom-label mb-1">
                Outlet
              </label>
              <select className="custom-select" id="outlet">
                <option>Erode</option>
                <option>Erode Brough Road</option>
                <option>Pallipalayam</option>
              </select>
            </div>
          </div>

          <div className="w-50 ps-1">
            <div className="p-2 radius-10 bg-body-color">
              <label htmlFor="outlet" className="custom-label mb-1">
                Channel
              </label>
              <select className="custom-select" id="outlet">
                <option>1SuperApp Market Place</option>
                <option>1SuperApp Market Own Place</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="p-3">
        <div className="card px-3 pb-3 pt-2">
          <Accordion className="px-0" defaultExpanded={true}>
            <AccordionSummary
              expandIcon={
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "43fd67bad621bf93fac936e25b16c7aa.svg"
                  }
                  className="w-10px"
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="px-1"
            >
              <h5 className="text-black mb-0 fw-bold">Briyani</h5>
              {/* <p>{}</p> */}
            </AccordionSummary>
            <div className="row align-items-center border-bottom"></div>
            <AccordionDetails className="w-100 p-0">
              <div className="w-100 px-0 py-3 accordian-detail border-bottom">
                <div className="d-flex align-items-center w-100 ">
                  <div className="">
                    <img
                      src={AppConfig.CDN_Image_url+"d11bb99ad9714b9425eb9dcf938eb240.jpg"}
                      className="product-img-60"
                    />
                  </div>
                  <div>
                    <div className="ps-2">
                      <img
                        src={AppConfig.CDN_Image_url+"e301ce3af353aab421a2f76c898a439d.png"}
                        className="w-14px"
                      />
                      <div className="heading larg my-1">
                        Chicken chettinadu pot briyani
                      </div>
                    <div className="marg">Quantity: 1kg</div>
                    </div>
                  </div>
                </div>
                <div className="pt-2 d-flex align-items-center w-100">
                  <div>
                    <div className="heading">₹280 - ₹360</div>
                    <div className="normal-sub-text strike">₹300 - ₹480</div>
                  </div>
                  <div className="ms-auto text-end">
                    <label className="switch">
                      <input type="checkbox"  />
                      <span className="slider round"></span>
                    </label>
                    <div className="heading mt-1">Product Group</div>
                    <div className="sub-text">
                      Next available at today 5PM
                    </div>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
}
